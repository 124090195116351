// @ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from "react";
import { DefaultLayout } from 'src/components/pages/common/Layout';
import { useLanguage } from "src/components/language/LanguageController";
import { useDispatch, useSelector } from "react-redux";
import { selectCustomersForDropDown } from "src/reducers/dairyCustomer";
import Eventer from "src/components/library/Eventer";
import CollectionQueueRow from "./CollectionQueueRow/CollectionQueueRow";
import moment from "moment";
import { getShiftNumber } from "src/components/library/milkUtils";
import { getDairyId } from "src/reducers/dairy";
import useDeviceStatus from "src/hooks/hardwareIntegration/useDeviceStatus/useDeviceStatus";
import ReactSelectBox from "src/components/core/ReactSelectBox";
import { fetchCollectionQueue } from "src/store/offline-store/collection-queue/thunk";
import { fetchCollectionQueueTotal } from "src/store/offline-store/collection-queue-total/thunk";
import { selectCollectionQueue } from "src/store/offline-store/collection-queue/selectors";
import CollectionTotal from "./CollectionTotal";
import AutoCollectionHeader from "./AutoCollectionHeader/AutoCollectionHeader";
import { addQueueItem, updateQueueItemAnalysis } from "../../../../../store/offline-store/collection-queue/collection-queue";
import { Redirect } from "react-router-dom";
import { formatCustomersWithCattle, filterSelectedCustomers } from "../../../../../utils/auto-collection-helper";
import _debounce from 'lodash/debounce';
import { useHotkeys } from 'react-hotkeys-hook';
import { captureException } from "src/components/library/errorTracking";

const AutoMilkCollection = () => {

    const { t } = useLanguage();
    const dairyId = useSelector(getDairyId);
    const shift = getShiftNumber();
    const dispatch = useDispatch();
    const autoCollectionSwitchLocal = localStorage.getItem("auto_collection_switch");
    const [autoCollectionSwitch, setAutoCollectionSwitch] = useState(autoCollectionSwitchLocal === "on");    
    const [selectedCustomer, setSelectedCustomer] = useState();
    const [selectedCattle, setSelectedCattle] = useState();
    const [isSelectCustomer, setIsSelectCustomer] = useState(false);
    const { devicesStatus } = useDeviceStatus();
    console.log("devicesStatus", devicesStatus);
    const isWeightMachineConnected = devicesStatus?.weightAnalyser?.isOpen;
    const customerSelectRef = useRef();

    const collectionQueue = useSelector(selectCollectionQueue);
    const allCustomers = useSelector(selectCustomersForDropDown);
    const customers = allCustomers.filter(customer => customer.type === "seller");

    const customersWithCattle = customers
        .reduce(formatCustomersWithCattle, [])
        .filter(filterSelectedCustomers(collectionQueue));

    const handleAutomaticCollectionSwitch = () => {
        setAutoCollectionSwitch(state => {
            const newState = !state;
            localStorage.setItem("auto_collection_switch", newState ? "on" : "off");
            return newState;
        });
    }

    const fetchQueueDetails = () => {
        dispatch(fetchCollectionQueueTotal({
            date: moment().format("YYYY-MM-DD"),
            shift,
            dairyId
        }));
        dispatch(fetchCollectionQueue({
            date: moment().format("YYYY-MM-DD"),
            shift,
            dairyId
        }));
    }

    const customerChangeHandler = useCallback((customer) => {
        if(customer) {
            setSelectedCustomer(customer);
            setSelectedCattle(customer.cattle);
            setIsSelectCustomer(true);
        }
    }, []);

    const parseBarcode = (barcode) => {
        let customerCode = barcode.toLowerCase().replace("ltr-", "");
        let [code, cattle] = customerCode.split("-");
        const alloweCode = (cattle.toLowerCase().startsWith("b") && cattle.length === 7) || (cattle.toLowerCase().startsWith("c") && cattle.length === 3)
        return {
            code: Number(code),
            cattle,
            alloweCode
        }
    }

    const onCustomerInputChange = (searchText, actionMeta) => {
        if(searchText.toLowerCase().startsWith("ltr") && searchText.length >= 12) {
            const { code, cattle, alloweCode } = parseBarcode(searchText);
            if(alloweCode) {
                const customer = customersWithCattle.find(customer => Number(customer.code) === code && customer.cattle === cattle.toLowerCase());
                if(customer) {
                    customerChangeHandler(customer);
                }
                customerSelectRef.current.blur();
                customerSelectRef.current.focus();
            }
        }
    }

    const onCustomerInputChangeDebounceFn = useCallback(_debounce(onCustomerInputChange, 50), []);

    const onMilkAnalysis = useCallback(({ detail }) => {
        const milkAnalysis = JSON.parse(detail);
        dispatch(updateQueueItemAnalysis({ milkAnalysis }));
    }, [dispatch]);

    useEffect(() => {
        Eventer.on("milkAnalysis", onMilkAnalysis);

        return () => {
            Eventer.off("milkAnalysis", onMilkAnalysis);
        }
    }, [onMilkAnalysis]);

    const updateQueue = async () => {
        const liter = autoCollectionSwitch && isWeightMachineConnected ? await window?.literAPI?.autoCollectionAPI?.getMilkWeightSync() : "";
        dispatch(addQueueItem({ shift, liter, selectedCustomer, selectedCattle }));
        setIsSelectCustomer(false);
        setSelectedCattle(null);
    }

    const keyDownHandler = (e, rowIndex, cellIndex) => {
        let nextElement;
        if (e.key === 'ArrowDown') {
            nextElement = document.getElementById(`cell-${rowIndex + 1}-${cellIndex}`);
        } else if (e.key === 'ArrowUp') {
            nextElement = document.getElementById(`cell-${rowIndex - 1}-${cellIndex}`);
        } else if (e.key === 'ArrowRight') {
            nextElement = document.getElementById(`cell-${rowIndex}-${cellIndex + 1}`);
        } else if (e.key === 'ArrowLeft') {
            nextElement = document.getElementById(`cell-${rowIndex}-${cellIndex - 1}`);
        }
        if(rowIndex === 0 && e.key === 'ArrowUp') {
            document.getElementById('input_dairy_customer_id').focus();
        } else if (nextElement) {
            const inputElement = nextElement.querySelector('input');
            const buttonElement = nextElement.querySelector('button');
            if (inputElement && !inputElement.disabled) {
                inputElement.focus();
            } else if (buttonElement && !buttonElement.disabled) {
                buttonElement.focus();
            } else {
                nextElement.focus();
            }
        }
    };

    useEffect(() => {
        if(isSelectCustomer && selectedCustomer?.dairy_customer_id) {
            updateQueue();
            customerSelectRef.current.clearValue();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSelectCustomer, selectedCustomer, selectedCattle, dispatch, shift]);

    useEffect(() => {
        fetchQueueDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            window?.literAPI?.autoCollectionAPI?.disconnectDevices();
        }
    }, []);

    useEffect(() => {
        try {
            console.log("autoCollectionSwitch updated", autoCollectionSwitch);
            if(autoCollectionSwitch) {
                window?.literAPI?.autoCollectionAPI?.connectDevices();
            } else {
                window?.literAPI?.autoCollectionAPI?.disconnectDevices().then(data => {
                    console.log("device disconnected", data);
                }).catch(error => console.log("error in disconnecting", error));
            }
        } catch(error) {
            captureException(error);
        }
    }, [autoCollectionSwitch]);

    useHotkeys("ctrl+f1", () => customerSelectRef.current?.focus());

    if(!window?.literAPI?.desktop) {
        return <Redirect to="/" />
    }

    return (

        <DefaultLayout
            title={t("text_auto_milk_collection")}
            toolbar={false}
            back
        >   
            <div data-auto="auto-milk-collection-container">
                <AutoCollectionHeader
                    autoCollectionSwitch={autoCollectionSwitch}
                    onAutoCollectionSwitch={handleAutomaticCollectionSwitch}
                />
                <CollectionTotal />
                <div className="shadow-sm">
                    <table className="table table-bordered table-green-header table-light font-size-85r">
                        <thead>
                            <tr>
                                <th className="p-2 width25p">{t("text_customer")}</th>
                                <th className="p-2 width5p">{t("text_cattle")}</th>
                                <th className="p-2 width13p">{t("text_liter")}</th>
                                <th className="p-2 width10p">{t("text_fat")}</th>
                                <th className="p-2 width10p">{t("text_clr")}</th>
                                <th className="p-2 width10p">{t("text_snf")}</th>
                                <th className="p-2 width10p">{t("text_rate")}</th>
                                <th className="p-2 width10p">{t("text_amount")}</th>
                                <th className="text-center p-2 width5p">{t("text_action")}</th>
                            </tr>
                        </thead>
                        <tbody>                            
                            <tr>
                                <td className="p-0">
                                    <ReactSelectBox
                                        noOptionsMessage={() => t("text_no_options")}
                                        placeholder={t("text_select_customer") + "..."}
                                        options={customersWithCattle}
                                        name="dairy_customer_id"
                                        inputId="input_dairy_customer_id"
                                        onChange={customerChangeHandler}
                                        onInputChange={onCustomerInputChangeDebounceFn}
                                        autoFocus
                                        components={{
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null
                                        }}
                                        ref={customerSelectRef}
                                    />
                                </td>
                                <td className="p-0">
                                </td>
                                <td colSpan={7}></td>
                            </tr>
                            {collectionQueue.map((item, rowIndex) => {
                                if(!item.customer) {
                                    return null;
                                }
                                return (
                                    <CollectionQueueRow
                                        key={`${item.customer.dairy_customer_id}_${item.cattle}`}
                                        rowIndex={rowIndex}
                                        queueItem={{...item}}
                                        shift={shift}
                                        onSave={fetchQueueDetails}
                                        onDelete={fetchQueueDetails}
                                        isWeightMachineConnected={isWeightMachineConnected}
                                        autoFocus={!autoCollectionSwitch && 0 === rowIndex}
                                        keyDownHandler={keyDownHandler}
                                        autoCollectionSwitch={autoCollectionSwitch}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </DefaultLayout>
    );
};

export default AutoMilkCollection;
