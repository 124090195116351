// @ts-nocheck
import { androidPrintBase64Image } from "../library/bluetoothPrinter";
import { captureException } from "../library/errorTracking";

export const printBase64Image = async (imageBase64) => {
    if(typeof cordova === "undefined") {
        await browserPrintBase64Image(imageBase64);
    } else if(window.literAPI){
        await window.literAPI.printContent({
            content: {
                html: `<img src="${imageBase64}">`
            }
        }, () => {});  
    } else {
        await androidPrintBase64Image(imageBase64);
    }
}

export const browserPrintBase64Image = (imageBase64) => {
    return new Promise((resolve, reject) => {
        const iframe = document.createElement('iframe');
        iframe.style.height = 0;
        iframe.style.visibility = 'hidden';
        iframe.style.width = 0;
        iframe.setAttribute('srcdoc', `<html><body></body></html>`);
        document.body.appendChild(iframe);
        iframe.onload = function() {
            const image = document.createElement('img');
            image.src = imageBase64;
            // image.style.maxWidth = '100%';
            const body = iframe.contentDocument.body;
            // body.style.textAlign = 'center';
            body.appendChild(image);
            image.onload = function() {
                iframe.contentWindow.print();
                resolve();
                document.body.removeChild(iframe);
            }
            image.onerror = function(errorEvent, url, line, col, errorObj) {
                document.body.removeChild(iframe);
                captureException({ errorEvent, url, line, col, errorObj })
                reject("Printing Error");
            };
        }
        iframe.onerror = function(errorEvent, url, line, col, errorObj) {
            captureException({ errorEvent, url, line, col, errorObj })
            reject("Printing Error");
        };
    });
}
