// @ts-nocheck
import { IconButton } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { calculateRate } from "src/components/helpers/rateListHelper";
import { useLanguage } from "src/components/language/LanguageController";
import { selectRateLists } from "src/reducers/dairyRateList";
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import useConfirm from '../../../../../confirmDialog/useConfirm';
import { errorHandler } from "src/components/library/response";
import LoaderIcon from "src/components/common/icons/LoaderIcon";
import TextField from '@material-ui/core/TextField';
import useMilkCollection from "src/hooks/useMilkCollection/useMilkCollection";
import InputAdornment from '@material-ui/core/InputAdornment';
import { CLR_INPUT_STEP, CLR_LOWER_LIMIT, CLR_UPPER_LIMIT, FAT_INPUT_STEP, FAT_LOWER_LIMIT, FAT_UPPER_LIMIT, MILK_INPUT_STEP, MILK_LOWER_LIMIT, MILK_UPPER_LIMIT, SNF_INPUT_STEP, SNF_LOWER_LIMIT, SNF_UPPER_LIMIT } from "src/_constants/rateList";
import { deleteCollectionQueueItem, saveCollectionQueueItem } from "src/store/offline-store/collection-queue/thunk";
import SimpleValidator from "src/components/common/SimpleValidator";
import { CLR_RULES, FAT_RULES, LITER_RULES, SNF_RULES } from "../../../../../../_constants/validations";

const CollectionQueueRow = ({ queueItem, rowIndex, shift, isWeightMachineConnected, autoFocus, keyDownHandler, autoCollectionSwitch }) => {
    const { t } = useLanguage();
    const { customer, milk, cattle, updateQueue } = queueItem;
    const { dairy_customer_id: dairyCustomerId } = customer;
    const literRef = useRef();
    const timeoutHandler = useRef(null);
    const dispatch = useDispatch();
    const [isRefreshing, setIsRefreshing] = useState(false);
    const rateLists = useSelector(selectRateLists);
    const confirm = useConfirm();
    const [milkState, setMilkState] = useState({});
    const validator = useRef(new SimpleValidator());
    const {
        customerType,
        rateBy,
        personalRate,
        rateListId
    } = useMilkCollection({
        dairyCustomerId
    });
    const clrSnfKey = rateBy === "snf" ? "clr" : "snf";

    const deleteEntry = async () => {
        try {
            await confirm({
                title: t("text_confirm"),
                description: t("text_delete_confirm"),
                confirmationText: t("text_yes"),
                cancellationText: t("text_no"),
            });
            dispatch(deleteCollectionQueueItem({
                shift: shift,
                rowIndex: rowIndex,
                cattle: queueItem.cattle,
                dairy_customer_id: queueItem.customer.dairy_customer_id
            }))            ;
        } catch (error) {
            error && errorHandler(error);
        }
    };
    const saveEntryDeffered = (milkState) => {
        timeoutHandler.current && clearTimeout(timeoutHandler.current);
        timeoutHandler.current = setTimeout(() => {
            dispatch(saveCollectionQueueItem({
                cattle,
                dairy_customer_id: dairyCustomerId,
                shift,
                ...milkState
            }));
        }, 1000);
    }

    const calculateRateAndGetTotal= ({ liter, fat, clr, snf }) => {
        const calculatedRate = calculateRate(rateLists, rateListId, {
            customerType,
            fat,
            clr,
            snf,
            cattle,
            personalRate,
            rateType: milk.rateType - 1
        });
        console.log("updateQueue:: calculatedRate", calculatedRate);
        return {
            [clrSnfKey]: calculatedRate?.[clrSnfKey],
            rate: calculatedRate.rate,
            total: (liter * calculatedRate.rate).toFixed(2)
        };
    }

    const calculateNewMilkState = (currentState) => {
        return {
            ...currentState,
            ...calculateRateAndGetTotal({ ...currentState })
        }
    }

    const handleMilkStateChange = (updateField) => {
        setMilkState((currentState) => {
            const newState = calculateNewMilkState({
                ...currentState,
                ...updateField,
            });            
            saveEntryDeffered(newState);
            return newState;
        });
    }

    const handleInput = (event) => {
        const { name, value } = event.target;
        handleMilkStateChange({ [name]: value });
    }

    const onRefreshWeight = async () => {
        setIsRefreshing(true)
        try {
            const liter = await window?.literAPI?.autoCollectionAPI?.getMilkWeightSync();
            handleMilkStateChange({ liter });
        } catch (error) {
            console.error(error);
        } finally {
            setIsRefreshing(false);
        }
    }

    const onInputKeyDownHandler = (e, rowIndex, cellIndex) => {
        if (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
            e.preventDefault();
        }
    };

    useEffect(() => {
        setMilkState((currentEntry) => ({
            ...currentEntry,
            liter: milk.liter,
            fat: milk.fat,
            clr: milk.clr,
            snf: milk.snf,
            rate: milk.rate,
            total: milk.total
        }));
    }, [milk]);

    useEffect(() => {
        if(updateQueue) {
            const { milk, cattle } = queueItem
            const { liter, fat, clr, snf } = milk;
            dispatch(saveCollectionQueueItem({
                dairy_customer_id: dairyCustomerId,
                shift,
                liter,
                fat,
                clr,
                snf,
                cattle,
                ...calculateRateAndGetTotal({ liter, fat, clr, snf })
            }));
        }
    }, [dispatch, updateQueue]);

    const clrCellProps = rateBy === "snf" ? { tabIndex: 0 } : {};
    const snfCellProps = rateBy === "clr" ? { tabIndex: 0 } : {};
    const literError = validator.current.message("liter", milkState.liter, LITER_RULES);
    const fatError = validator.current.message("fat", milkState.fat, FAT_RULES);
    const clrError = validator.current.message("clr", milkState.clr, CLR_RULES);
    const snfError = validator.current.message("snf", milkState.snf, SNF_RULES);

    const isRateBySNF = rateBy === "snf";
    const isRateByCLR = rateBy === "clr";
    
    return (
        <tr id={`${queueItem.cattle}_${queueItem.customer.dairy_customer_id}`} data-row-index={rowIndex}>
            <td
                id={`cell-${rowIndex}-${0}`}
                className="py-2"
                onKeyDown={(e) => keyDownHandler(e, rowIndex, 0)}
                onClick={(e) => e.target.focus()}
                tabIndex="0"
            >
                {`${queueItem.customer.code} - ${queueItem.customer.name}`}
            </td>
            <td
                id={`cell-${rowIndex}-${1}`}
                className="py-2"
                onKeyDown={(e) => keyDownHandler(e, rowIndex, 1)}
                onClick={(e) => e.currentTarget.focus()}
                tabIndex="0"
            >
                {t(`text_${queueItem.cattle}`)}
            </td>
            <td
                id={`cell-${rowIndex}-${2}`}
                className="p-0"
                onKeyDown={(e) => keyDownHandler(e, rowIndex, 2)}
            >
                <TextField
                    type="number"
                    size="small"
                    variant="outlined"
                    name="liter"
                    fullWidth
                    value={milkState.liter}
                    onChange={handleInput}
                    inputRef={literRef}
                    inputProps={{
                        min: MILK_LOWER_LIMIT,
                        max: MILK_UPPER_LIMIT,
                        step: MILK_INPUT_STEP,
                        'aria-label': t("text_liter")
                    }}
                    InputProps={{
                        readOnly: autoCollectionSwitch,
                        endAdornment: 
                            isWeightMachineConnected && <InputAdornment position="end">
                                <IconButton size="small" aria-label="Refresh weight" onClick={onRefreshWeight} disabled={isRefreshing}>
                                    {isRefreshing ? <LoaderIcon fontSize="small" spin={true} /> : <RefreshIcon fontSize="small" />}
                                </IconButton>
                            </InputAdornment>
                    }}
                    autoFocus={autoFocus}
                    onKeyDown={onInputKeyDownHandler}
                    error={!validator.current.fieldValid("liter")}
                />
            </td>
            <td
                id={`cell-${rowIndex}-${3}`}
                className="p-0"
                onKeyDown={(e) => keyDownHandler(e, rowIndex, 3)}
            >
                <TextField
                    type="number"
                    size="small"
                    variant="outlined"
                    name="fat"
                    fullWidth
                    value={milkState.fat}
                    onChange={handleInput}
                    inputProps={{
                        readOnly: autoCollectionSwitch,
                        min: FAT_LOWER_LIMIT,
                        max: FAT_UPPER_LIMIT,
                        step: FAT_INPUT_STEP,
                        'aria-label': t("text_fat")
                    }}
                    onKeyDown={onInputKeyDownHandler}
                    error={!validator.current.fieldValid("fat")}
                />
            </td>
            <td
                id={`cell-${rowIndex}-${4}`}
                className="p-0"
                onKeyDown={(e) => keyDownHandler(e, rowIndex, 4)}
                onClick={(e) => e.currentTarget.focus()}
                { ...clrCellProps }
            >
                <TextField
                    type="number"
                    size="small"
                    variant="outlined"
                    name="clr"
                    fullWidth
                    value={milkState.clr}
                    onChange={handleInput}
                    disabled={isRateBySNF}
                    inputProps={{
                        readOnly: autoCollectionSwitch,
                        min: CLR_LOWER_LIMIT,
                        max: CLR_UPPER_LIMIT,
                        step: CLR_INPUT_STEP,
                        'aria-label': t("text_clr")
                    }}
                    onKeyDown={onInputKeyDownHandler}
                    error={!isRateBySNF && !validator.current.fieldValid("clr")}
                />
            </td>
            <td
                id={`cell-${rowIndex}-${5}`}
                className="p-0"
                onKeyDown={(e) => keyDownHandler(e, rowIndex, 5)}
                onClick={(e) => e.currentTarget.focus()}
                {...snfCellProps}
            >
                <TextField
                    type="number"
                    size="small"
                    variant="outlined"
                    name="snf"
                    fullWidth
                    value={milkState.snf}
                    onChange={handleInput}
                    disabled={isRateByCLR}
                    inputProps={{
                        readOnly: autoCollectionSwitch,
                        min: SNF_LOWER_LIMIT,
                        max: SNF_UPPER_LIMIT,
                        step: SNF_INPUT_STEP,
                        'aria-label': t("text_snf")
                    }}
                    onKeyDown={onInputKeyDownHandler}
                    error={!isRateByCLR && !validator.current.fieldValid("snf")}
                />
            </td>
            <td
                id={`cell-${rowIndex}-${6}`}
                className="p-0"
                onKeyDown={(e) => keyDownHandler(e, rowIndex, 6)}
                onClick={(e) => e.currentTarget.focus()}
                tabIndex="0"
            >
                <TextField  
                    type="number"
                    size="small"
                    variant="outlined"
                    name="rate"
                    value={milkState.rate}
                    fullWidth
                    disabled
                    inputProps={{
                        'aria-label': t("text_rate")
                    }}
                    onKeyDown={onInputKeyDownHandler}
                />
            </td>
            <td
                id={`cell-${rowIndex}-${7}`}
                className="p-0"
                onKeyDown={(e) => keyDownHandler(e, rowIndex, 7)}
                tabIndex="0"
            >
                <TextField
                    type="number"
                    size="small"
                    variant="outlined"
                    name="total"
                    value={milkState.total}
                    fullWidth
                    disabled
                    inputProps={{
                        'aria-label': t("text_total")
                    }}
                    onKeyDown={onInputKeyDownHandler}
                />
            </td >
            <td
                id={`cell-${rowIndex}-${8}`}
                className="text-center p-0"
                onKeyDown={(e) => keyDownHandler(e, rowIndex, 8)}
                tabIndex="0"
            >
                <IconButton size="small" color="secondary" onClick={deleteEntry} aria-label="Delete entry">
                    <DeleteIcon />
                </IconButton>
            </td>
        </tr>
    );
};

export default CollectionQueueRow;