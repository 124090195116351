// @ts-nocheck
import milkImg from "../../../assets/icons/milk_1.svg";
import customerImg from "../../../assets/icons/customer.svg";
import productImg from "../../../assets/icons/take-away.svg";
import billImg from "../../../assets/icons/receipt.svg";
import loanImg from "../../../assets/icons/payment.svg";
import reportImg from "../../../assets/icons/report.svg";
import settingImg from "../../../assets/icons/settings.svg";
import milkImgActive from "../../../assets/icons/milk_1-white.svg";
import customerImgActive from "../../../assets/icons/customer-white.svg";
import productImgActive from "../../../assets/icons/take-away-white.svg";
import billImgActive from "../../../assets/icons/receipt-white.svg";
import loanImgActive from "../../../assets/icons/payment-white.svg";
import reportImgActive from "../../../assets/icons/report-white.svg";
import settingImgActive from "../../../assets/icons/settings-white.svg";
import gestationIcon from "../../../assets/icons/sidebar_nav/gestation.svg";
import gestationIconActive from "../../../assets/icons/sidebar_nav/gestation-white.svg";
import { useBrowser, useMobile } from 'src/components/library/detect-device';
import { useLanguage } from "src/components/language/LanguageController";
import { usePermission } from "../AccessPermission";
import { ABILITY_ADVANCE_PAYMENT, ABILITY_DAIRY_BILL, ABILITY_DAIRY_CUSTOMERS, ABILITY_DAIRY_RATE_LIST, ABILITY_DAIRY_REPORT, ABILITY_DAIRY_SETTING, ABILITY_DAIRY_USERS, ABILITY_LOAN, ABILITY_MILK_COLLECTION, ABILITY_PRODUCT_MANAGEMENT, ABILITY_PRODUCT_SALE, PERMISSION_ADD, PERMISSION_CUSTOMER_REPORT, PERMISSION_DAIRY_REPORT, PERMISSION_DETAIL, PERMISSION_SALE_ADD, PERMISSION_SALE_DELETE, PERMISSION_SALE_VIEW, PERMISSION_VIEW, PERMISSION_VIEW_CUSTOMER_BILL, PERMISSION_VIEW_DAIRY_BILL } from "../../../_constants/permissions";
import { checkFeatureInSubscription } from "../FeatureFlag/FeatureFlag";

const useMenu = () => {
    const { t } = useLanguage();
    const isBrowser = useBrowser();
    const isMobile = useMobile();
    const { hasAccess } = usePermission();
    const hasAutoCollectionAccess = window?.literAPI?.desktop
        && hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_ADD)
        && (
            checkFeatureInSubscription("offline_collection", false)
            || checkFeatureInSubscription("automatic_collection", false)
        );

    return [
        {
            key: "Customer",
            text: "text_customers",
            image: customerImg,
            imageActive: customerImgActive,
            activePaths: ["/customers", "/customers/add", "/customers/edit/:dairy_customer_id"],
            hasAccess: hasAccess(ABILITY_DAIRY_CUSTOMERS, PERMISSION_VIEW),
            subMenu: () => {
                return [
                    {
                        key: "customer_list",
                        text: "text_customers_list",
                        path: "/customers",
                        hasAccess: hasAccess(ABILITY_DAIRY_CUSTOMERS, PERMISSION_VIEW),
                    },
                    {
                        key: "add_customer",
                        text: "text_add_customer",
                        path: "/customers/add",
                        hasAccess: hasAccess(ABILITY_DAIRY_CUSTOMERS, PERMISSION_ADD),
                    }
                ]
            }
        },
        {
            key: "Milk",
            text: "text_buy_sell",
            image: milkImg,
            imageActive: milkImgActive,
            activePaths: ["/milk/collection", "/milk/totalCollection", "/milk/summary", "/milk/customer/:dairyCustomerId?"],
            hasAccess: hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_ADD) || hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL),
            subMenu: () => {
                const subMenus = [
                    {
                        key: "milk_collection",
                        text: "text_milk_buy_sell",
                        path: "/milk/collection",
                        hasAccess: hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_ADD)
                    },
                    {
                        key: "milk_collection_auto",
                        text: "text_auto_collection",
                        path: "/milk/collection/auto",
                        hasAccess: hasAutoCollectionAccess
                    },
                    {
                        key: "total_collection",
                        text: "text_total_buy_sell",
                        path: "/milk/totalCollection",
                        feature: "daily_milk_total",
                        hasAccess: hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL)
                    }
                ];
                if(isMobile) {
                    subMenus.push({
                        key: "collection_summary",
                        text: "text_buy_sell_summary",
                        path: "/milk/summary",
                        feature: "dairy_milk_summary",
                        hasAccess: hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL)
                    });
                }
                subMenus.push({
                    key: "customer_milk",
                    text: "text_customer_milk",
                    path: "/milk/customer",
                    hasAccess: hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL)
                });
                return subMenus;
            }
        },
        {
            key: "Product",
            text: "text_products",
            image: productImg,
            imageActive: productImgActive,
            feature: "product_management",
            activePaths: ["/dairy/product/addSales", "/dairy/product/salesSummary", "/dairy/product"],
            hasAccess: hasAccess(ABILITY_PRODUCT_MANAGEMENT, PERMISSION_VIEW)
                || hasAccess(ABILITY_PRODUCT_SALE, PERMISSION_SALE_VIEW)
                || hasAccess(ABILITY_PRODUCT_SALE, PERMISSION_SALE_ADD),
            subMenu: () => {
                const subMenus = [];
                if(isMobile) {
                    subMenus.push({
                        key: "add_sales",
                        text: "text_add_product_sell",
                        path: "/dairy/product/addSales",
                        feature: "product_management",
                        hasAccess: hasAccess(ABILITY_PRODUCT_SALE, PERMISSION_SALE_ADD)
                    });
                }
                subMenus.push({
                    key: "product_summary",
                    text: `${!isMobile && t("text_add_product_sell")} / ${t("text_product_summary")}`,
                    path: "/dairy/product/salesSummary",
                    feature: "product_management",
                    hasAccess: hasAccess(ABILITY_PRODUCT_SALE, PERMISSION_SALE_VIEW) || hasAccess(ABILITY_PRODUCT_SALE, PERMISSION_SALE_ADD)
                });
                subMenus.push({
                    key: "manage_products",
                    text: "text_manage_products",
                    path: "/dairy/product",
                    feature: "product_management",
                    hasAccess: hasAccess(ABILITY_PRODUCT_MANAGEMENT, PERMISSION_VIEW)
                });
                return subMenus;
            }
        },
        {
            key: "Bill",
            text: "text_bill",
            image: billImg,
            imageActive: billImgActive,
            feature: "billing",
            activePaths: ["/dairy/bill", "/dairy/bill/customer/:dairyCustomerId?", "/dairy/advancePayment", "/dairy/paymentSummary", "/dairy/account/paymentHistory"],
            hasAccess: hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_DAIRY_BILL)
                || hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_CUSTOMER_BILL)
                || hasAccess(ABILITY_ADVANCE_PAYMENT, PERMISSION_VIEW)
                || hasAccess(ABILITY_ADVANCE_PAYMENT, PERMISSION_ADD),
            subMenu: () => {
                return [
                    {
                        key: "dairy_bill",
                        text: "text_dairy_bill",
                        path: "/dairy/bill",
                        feature: "dairy_billing",
                        hasAccess: hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_DAIRY_BILL)
                    },
                    {
                        key: "customers_bill",
                        text: "text_customers_bill",
                        path: "/dairy/bill/customer",
                        feature: "customer_billing",
                        hasAccess: hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_CUSTOMER_BILL)
                    },
                    {
                        key: "pay_in_advance",
                        text: "text_pay_in_advance",
                        path: "/dairy/advancePayment",
                        feature: "advance_payment",
                        hasAccess: hasAccess(ABILITY_ADVANCE_PAYMENT, PERMISSION_VIEW)
                            || hasAccess(ABILITY_ADVANCE_PAYMENT, PERMISSION_ADD)
                    },
                    {
                        key: "payment_summary",
                        text: "text_payment_summary",
                        path: "/dairy/paymentSummary",
                        feature: "dairy_billing",
                        hasAccess: hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_CUSTOMER_BILL)
                    },
                    {
                        key: "payment_history",
                        text: "text_customer_payment_statement",
                        path: "/dairy/account/paymentHistory",
                        feature: "dairy_billing",
                        hasAccess: hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_CUSTOMER_BILL)
                    }
                ]
            }
        },
        {
            key: "Loan",
            text: "text_loan",
            image: loanImg,
            imageActive: loanImgActive,
            feature: "loan",
            path: "/dairy/loan",
            activePaths: ["/dairy/loan/sanction", "/dairy/loan", "/dairy/loan/customer"],
            hasAccess: hasAccess(ABILITY_LOAN, PERMISSION_VIEW) || hasAccess(ABILITY_LOAN, PERMISSION_ADD),
            subMenu: () => {
                const subMenus = [];
                if(!isBrowser) {
                    subMenus.push({
                        key: "sanction_loan",
                        text: "text_sanction_loan",
                        path: "/dairy/loan/sanction",
                        hasAccess: hasAccess(ABILITY_LOAN, PERMISSION_ADD)
                    });
                }
                subMenus.push({
                    key: "loan_dashboard",
                    text: "text_loan_dashboard",
                    path: "/dairy/loan",
                    hasAccess: hasAccess(ABILITY_LOAN, PERMISSION_VIEW)
                });
                if(!isBrowser) {
                    subMenus.push({
                        key: "customers_loan",
                        text: "text_customers_loan",
                        path: "/dairy/loan/customer",
                        hasAccess: hasAccess(ABILITY_LOAN, PERMISSION_VIEW)
                    });
                }
                return subMenus;
            }
        },
        {
            key: "reports",
            text: "text_reports",
            image: reportImg,
            imageActive: reportImgActive,
            path: "/dairy/report",
            activePaths: ["/dairy/report"],
            feature: "reports",
            hasAccess: hasAccess(ABILITY_DAIRY_REPORT, PERMISSION_DAIRY_REPORT) 
                || hasAccess(ABILITY_DAIRY_REPORT, PERMISSION_CUSTOMER_REPORT)
        },
        {
            key: "gestation",
            text: "gestation_text",
            image: gestationIcon,
            imageActive: gestationIconActive,
            path: "/gestation",
            activePaths: ["/gestation"],
            hasAccess: true
        },
        {
            key: "setting",
            text: "text_settings",
            image: settingImg,
            imageActive: settingImgActive,
            activePaths: ["/dairy/setting/rateList", "/dairy/setting"],
            hasAccess: hasAccess(ABILITY_DAIRY_RATE_LIST, PERMISSION_VIEW)
                || hasAccess(ABILITY_DAIRY_SETTING, PERMISSION_VIEW)
                || hasAccess(ABILITY_DAIRY_USERS, PERMISSION_VIEW),
            subMenu: () => {
                return [
                    {
                        key: "rate_list",
                        text: "text_rate_list",
                        path: "/dairy/setting/rateList",
                        hasAccess: hasAccess(ABILITY_DAIRY_RATE_LIST, PERMISSION_VIEW)
                    },
                    {
                        key: "dairy_settings",
                        text: "text_dairy_settings",
                        path: "/dairy/setting",
                        hasAccess: hasAccess(ABILITY_DAIRY_SETTING, PERMISSION_VIEW)
                    },
                    {
                        key: "dairy_users",
                        text: "text_dairy_users",
                        path: "/dairy/setting/users",
                        hasAccess: hasAccess(ABILITY_DAIRY_USERS, PERMISSION_VIEW)
                    }
                ]
            }
        }
    ];
};

export default useMenu;