// @ts-nocheck
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

import withLanguage from "../../language/LanguageController";

import gestationIcon from "../../../assets/icons/cattle_gestation.png";
import customerImg from "../../../assets/icons/customer.svg";
import customerLoanImg from "../../../assets/icons/customer_loan.png";
import loanDashboardImg from "../../../assets/icons/loan-dashboard.png";
import addLoanImg from "../../../assets/icons/loanAdd.png";
import milkImg from "../../../assets/icons/milk_1.svg";
import loanImg from "../../../assets/icons/payment.svg";
import billImg from "../../../assets/icons/receipt.svg";
import reportImg from "../../../assets/icons/report.png";
import rewardIcon from "../../../assets/icons/reward.png";
import settingImg from "../../../assets/icons/settings.svg";
import membershipIcon from "../../../assets/icons/subscription.png";
import tableDataImg from "../../../assets/icons/table-data.png";
import productImg from "../../../assets/icons/take-away.svg";

import { Drawer, Grid, Link, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuIcon from '@material-ui/icons/Menu';
import { ActionSheetButton, Icon } from "react-onsenui";
import { useHistory } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { checkFeatureInSubscription } from "src/components/common/FeatureFlag/FeatureFlag";
import { useBrowser, useMobile } from "src/components/library/detect-device";
import analytics from "src/components/library/firebase/analytics";
import { ABILITY_ADVANCE_PAYMENT, ABILITY_DAIRY_BILL, ABILITY_DAIRY_CUSTOMERS, ABILITY_DAIRY_RATE_LIST, ABILITY_DAIRY_REPORT, ABILITY_DAIRY_SETTING, ABILITY_DAIRY_USERS, ABILITY_LOAN, ABILITY_MILK_COLLECTION, ABILITY_PRODUCT_MANAGEMENT, ABILITY_PRODUCT_SALE, PERMISSION_ADD, PERMISSION_CUSTOMER_REPORT, PERMISSION_DAIRY_REPORT, PERMISSION_DETAIL, PERMISSION_SALE_ADD, PERMISSION_VIEW, PERMISSION_VIEW_CUSTOMER_BILL, PERMISSION_VIEW_DAIRY_BILL } from "../../../_constants/permissions";
import blogIcon from "../../../assets/icons/blog.png";
import customerImgActive from "../../../assets/icons/customer-white.svg";
import milkImgActive from "../../../assets/icons/milk_1-white.svg";
import billImgActive from "../../../assets/icons/receipt-white.svg";
import productImgActive from "../../../assets/icons/take-away-white.svg";
import ActionSheet from "../../../ons/components/ActionSheet";
import { HasPermission, usePermission } from "../../common/AccessPermission";
import { SecondaryFab } from "../../core/Fab";

const DairyMainNavigation = ({ t }) => {
    const history = useHistory();
    const isMobile = useMobile();
    const isBrowser = useBrowser();
    const { hasAccess } = usePermission();
    const [isCustomerMenuOpen, setIsCustomerMenuOpen] = useState(false);
    const [isMilkMenuOpen, setIsMilkMenuOpen] = useState(false);
    const [isProductMenuOpen, setIsProductMenuOpen] = useState(false);
    const [isBillMenuOpen, setIsBillMenuOpen] = useState(false);
    const [isLoanMenuOpen, setIsLoanMenuOpen] = useState(false);
    const [isSettingMenuOpen, setIsSettingMenuOpen] = useState(false);
    const [moreMenuDrawer, setMoreMenuDrawer] = useState(false);
    const [active, setActive] = useState("");

    const showMoreMenu = () => {
        setIsCustomerMenuOpen(false);
        setIsMilkMenuOpen(false);
        setIsProductMenuOpen(false);
        setIsBillMenuOpen(false);
        setIsLoanMenuOpen(false);
        setIsSettingMenuOpen(false);
        setMoreMenuDrawer(true);
    }

    const showSubMenu = (type, feature = null) => {
        analytics.logEvent("showSubMenu", {
            subMenu: type
        });
        if(!feature || checkFeatureInSubscription(feature)) {
            setIsCustomerMenuOpen(type === "customer" ? true : false);
            setIsMilkMenuOpen(type === "milk" ? true : false);
            setIsProductMenuOpen(type === "product" ? true : false);
            setIsBillMenuOpen(type === "bill" ? true : false);
            setIsLoanMenuOpen(type === "loan" ? true : false);
            setIsSettingMenuOpen(type === "setting" ? true : false);
        }
    }

    const changePage = (pathToGo, feature = null) => {
        if(!feature || checkFeatureInSubscription(feature)) {
            history.push(pathToGo);
        }
    }

    const getMenuIcon = (Icon, isMui) => {
        return isMui ? <Icon /> : <img src={Icon} className="mt-1 max-w-25" alt=""/>;
    }

    const hasCustomersAccess = hasAccess(ABILITY_DAIRY_CUSTOMERS, PERMISSION_ADD) || hasAccess(ABILITY_DAIRY_CUSTOMERS, PERMISSION_VIEW);
    const hasMilkPermission = hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_ADD) || hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL) || hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL) || hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL);
    const hasProductAccess = hasAccess(ABILITY_PRODUCT_SALE, PERMISSION_SALE_ADD) || hasAccess(ABILITY_PRODUCT_SALE, PERMISSION_SALE_ADD) || hasAccess(ABILITY_PRODUCT_MANAGEMENT, PERMISSION_VIEW);
    const hasBillPermission = hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_DAIRY_BILL) || hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_CUSTOMER_BILL) || hasAccess(ABILITY_ADVANCE_PAYMENT, PERMISSION_VIEW) || hasAccess(ABILITY_ADVANCE_PAYMENT, PERMISSION_ADD) || hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_CUSTOMER_BILL);
    const hasLoanAccess = hasAccess(ABILITY_LOAN, PERMISSION_VIEW) || hasAccess(ABILITY_LOAN, PERMISSION_ADD);
    const hasReportAccess = hasAccess(ABILITY_DAIRY_REPORT, PERMISSION_DAIRY_REPORT) || hasAccess(ABILITY_DAIRY_REPORT, PERMISSION_CUSTOMER_REPORT);
    const hasSettingAccess = hasAccess(ABILITY_DAIRY_RATE_LIST, PERMISSION_VIEW) || hasAccess(ABILITY_DAIRY_SETTING, PERMISSION_VIEW) || hasAccess(ABILITY_DAIRY_USERS, PERMISSION_VIEW);
    const hasAutoCollectionAccess = window?.literAPI?.desktop
        && hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_ADD)
        && (
            checkFeatureInSubscription("offline_collection", false)
            || checkFeatureInSubscription("automatic_collection", false)
        );

    if(isBrowser) {
        return null;
    }

    const milkSubMenus = [
        {
            text: t("text_milk_buy_sell"),
            testid:"nav_milk_collection",
            onClick: () => changePage("/milk/collection"),
            hasAccess: hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_ADD)
        },
        {
            testid: "nav_milk_collection_auto",
            text: t("text_auto_collection"),
            onClick: () => changePage("/milk/collection/auto"),
            hasAccess: hasAutoCollectionAccess
        },
        {
            text: t("text_total_buy_sell"),
            testid: "nav_total_collection",
            onClick: () => changePage("/milk/totalCollection", "daily_milk_total"),
            hasAccess: hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL)
        },
        {
            text: t("text_buy_sell_summary"),
            testid: "nav_collection_summary",
            onClick: () => changePage("/milk/summary", "dairy_milk_summary"),
            isMobile: true,
            hasAccess: hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL)
        },
        {
            text: t("text_customer_milk"),
            testid: "nav_customer_milk",
            onClick: () => changePage("/milk/customer"),
            hasAccess: hasAccess(ABILITY_MILK_COLLECTION, PERMISSION_DETAIL)
        }
    ]

    return (
        <>
            <BottomNavigation
                value={active}
                id="dairy-main-navigation"
                className="border-top fixed-bottom w-100 justify-content-start justify-content-sm-center dairy-main-navigation align-items-center h-auto shadow-top"
            >
                {hasCustomersAccess && <BottomNavigationAction
                    showLabel={true}
                    className="text-dark"
                    label={<span className="d-inline-block mt-1">{t("text_customers")}</span>}
                    value={"Customer"}
                    onClick={(e) => {
                        setActive("Customer");
                        showSubMenu("customer");
                    }}
                    icon={getMenuIcon(active === "Customer" ? customerImgActive : customerImg)}
                />}
                {hasMilkPermission && <BottomNavigationAction
                    showLabel={true}
                    className="text-dark"
                    label={<span className="d-inline-block mt-1">{t("text_buy_sell")}</span>}
                    value={"Milk"}
                    onClick={() => {
                        setActive("Milk");
                        showSubMenu("milk");
                    }}
                    icon={getMenuIcon(active === "Milk" ? milkImgActive : milkImg)}
                />}
                {hasProductAccess && <BottomNavigationAction
                    showLabel={true}
                    className="text-dark"
                    label={<span className="d-inline-block mt-1">{t("text_products")}</span>}
                    value="Product"
                    onClick={() => {
                        setActive("Product");
                        showSubMenu("product", "product_management");
                    }}
                    icon={getMenuIcon(active === "Product" ? productImgActive : productImg)}
                />}
                {hasBillPermission && <BottomNavigationAction
                    showLabel={true}
                    className="text-dark"
                    label={<span className="d-inline-block mt-1">{t("text_bill")}</span>}
                    value={"Bill"}
                    onClick={() => {
                        setActive("Bill");
                        showSubMenu("bill", "billing");
                    }}
                    icon={getMenuIcon(active === "Bill" ? billImgActive : billImg)}
                />}
                <BottomNavigationAction
                    showLabel={true}
                    className="text-dark"
                    label={<span className="d-inline-block font-weight-bold mt-1">{t("text_more")}</span>}
                    value={"More"}
                    onClick={showMoreMenu}
                    icon={<MenuIcon className="font-size-1_8r"/>}
                />
            </BottomNavigation>
            
            <Drawer
                anchor={"bottom"}
                open={moreMenuDrawer}
                onClose={() => setMoreMenuDrawer(false)}
                className="dairy-main-navigation-drawer"
                elevation={0}
                PaperProps={{
                    className: "overflow-visible-i"
                }}
            >
                <div className="d-flex justify-content-center">
                    <SecondaryFab
                        aria-label="add"
                        className="mx-1 position-absolute top-n15"
                        size="medium"
                        variant="extended"
                        onClick={() => setMoreMenuDrawer(false)}
                    >
                        <KeyboardArrowDownIcon fontSize="large" className="text-white" />
                    </SecondaryFab>
                </div>
                <div
                    role="presentation"
                    onClick={e => setMoreMenuDrawer(false)}
                    onKeyDown={e => setMoreMenuDrawer(false)}
                    className="py-2 px-2"
                >
                    {hasLoanAccess && <div className="mb-3">
                        <Typography
                            variant="body1"
                            className="font-weight-bold mb-2 pb-1 border-bottom border-secondary"
                        >
                            {t("text_loan")}
                        </Typography>
                        <Grid container className="pt-1" spacing={3}>
                            <HasPermission ability={ABILITY_LOAN} access={PERMISSION_ADD}>
                                <Grid item xs={3}>
                                    <Link
                                        data-auto="nav_sanction_loan"
                                        to="/dairy/loan/sanction"
                                        component={RouterLink}
                                        className="d-flex flex-column align-items-center text-center text-dark"
                                    >
                                        <img
                                            src={addLoanImg}
                                            className="mb-2 min-w-30 max-w-30"
                                            alt=""
                                        />
                                        <Typography variant="body2">{t("text_sanction_loan")}</Typography>
                                    </Link>
                                </Grid>
                            </HasPermission>
                            <HasPermission ability={ABILITY_LOAN} access={PERMISSION_VIEW}>
                                <Grid item xs={3}>
                                    <Link
                                        data-auto="nav_loan_dashboard"
                                        to="/dairy/loan"
                                        component={RouterLink}
                                        className="d-flex flex-column align-items-center text-center text-dark"
                                    >
                                        <img
                                            src={loanDashboardImg}
                                            className="mb-2 min-w-30 max-w-30"
                                            alt=""
                                        />
                                        <Typography variant="body2">{t("text_loan_dashboard")}</Typography>
                                    </Link>
                                </Grid>
                            </HasPermission>
                            <HasPermission ability={ABILITY_LOAN} access={PERMISSION_VIEW}>
                                <Grid item xs={3}>
                                    <Link
                                        data-auto="nav_customers_loan"
                                        to="/dairy/loan/customer"
                                        component={RouterLink}
                                        className="d-flex flex-column align-items-center text-center text-dark"
                                    >
                                        <img
                                            src={customerLoanImg}
                                            className="mb-2 min-w-30 max-w-30"
                                            alt=""
                                        />
                                        <Typography variant="body2">{t("text_customers_loan")}</Typography>
                                    </Link>
                                </Grid>
                            </HasPermission>
                        </Grid>
                    </div>}

                    {(hasReportAccess || hasSettingAccess) && <div className="mb-3">
                        <Typography
                            variant="body1"
                            className="font-weight-bold mb-2 pb-1 border-bottom border-secondary"
                        >
                            {`${t("text_settings")} & ${t("text_reports")}`}
                        </Typography>
                        <Grid container className="pt-1" spacing={3}>
                            <HasPermission ability={ABILITY_DAIRY_SETTING} access={PERMISSION_VIEW}>
                                <Grid item xs={3}>
                                    <Link
                                        data-auto="nav_dairy_setting"
                                        to="/dairy/setting"
                                        component={RouterLink}
                                        className="d-flex flex-column align-items-center text-center text-dark nav_dairy_setting"
                                    >
                                        <img
                                            src={settingImg}
                                            className="mb-2 min-w-30 max-w-30"
                                            alt=""
                                        />
                                        <Typography variant="body2">{t("text_dairy_settings")}</Typography>
                                    </Link>
                                </Grid>
                            </HasPermission>
                            <HasPermission ability={ABILITY_DAIRY_RATE_LIST} access={PERMISSION_VIEW}>
                                <Grid item xs={3}>
                                    <Link
                                        data-auto="nav_rate_list"
                                        to="/dairy/setting/rateList"
                                        component={RouterLink}
                                        className="d-flex flex-column align-items-center text-center text-dark nav_rate_list"
                                    >
                                        <img
                                            src={tableDataImg}
                                            className="mb-2 min-w-30 max-w-30"
                                            alt=""
                                        />
                                        <Typography variant="body2">{t("text_rate_list")}</Typography>
                                    </Link>
                                </Grid>
                            </HasPermission>
                            {hasReportAccess && <Grid item xs={3}>
                                <Link
                                    data-auto="nav_dairy_report"
                                    onClick={() => changePage("/dairy/report", "reports")}
                                    component={RouterLink}
                                    className="d-flex flex-column align-items-center text-center text-dark nav_dairy_report"
                                >
                                    <img
                                        src={reportImg}
                                        className="mb-2 min-w-30 max-w-30"
                                        alt=""
                                    />
                                    <Typography variant="body2">{t("text_reports")}</Typography>
                                </Link>
                            </Grid>}
                            <HasPermission ability={ABILITY_DAIRY_USERS} access={PERMISSION_VIEW}>
                                <Grid item xs={3}>
                                    <Link
                                        data-auto="nav_dairy_users_setting"
                                        onClick={(e) => {e.preventDefault(); changePage("/dairy/setting/users", ABILITY_DAIRY_USERS)}}
                                        component={RouterLink}
                                        className="d-flex flex-column align-items-center text-center text-dark nav_dairy_report"
                                    >
                                        <img
                                            src={reportImg}
                                            className="mb-2 min-w-30 max-w-30"
                                            alt=""  
                                        />
                                        <Typography variant="body2">{t("text_dairy_users")}</Typography>
                                    </Link>
                                </Grid>
                            </HasPermission>
                        </Grid>
                    </div>}

                    <div className="mb-3">
                        <Typography
                            variant="body1"
                            className="font-weight-bold mb-2 pb-1 border-bottom border-secondary"
                        >
                            {t("text_other")}
                        </Typography>
                        <Grid container className="pt-1" spacing={3}>
                            <Grid item xs={3}>
                                <Link
                                    data-auto="nav_subscription"
                                    to="/subscription"
                                    component={RouterLink}
                                    className="d-flex flex-column align-items-center text-center text-dark nav_subscription"
                                >
                                    <img
                                        src={membershipIcon}
                                        className="mb-2 min-w-30 max-w-30"
                                        alt=""
                                    />
                                    <Typography variant="body2">{t("text_subscription")}</Typography>
                                </Link>
                            </Grid>
                            <Grid item xs={3}>
                                <a
                                    data-auto="nav_rewards"
                                    href="https://blog.liter.live"
                                    target="_blank"
                                    className="d-flex flex-column align-items-center text-center text-dark nav_rewards" rel="noreferrer"
                                >
                                    <img
                                        src={blogIcon}
                                        className="mb-2 min-w-30 max-w-30"
                                        alt=""
                                    />
                                    <Typography variant="body2">{t("text_blog")}</Typography>
                                </a>
                            </Grid>
                            <Grid item xs={3}>
                                <Link
                                    data-auto="nav_rewards"
                                    to="/rewards"
                                    component={RouterLink}
                                    className="d-flex flex-column align-items-center text-center text-dark nav_rewards"
                                >
                                    <img src={rewardIcon} 
                                        className="mb-2 min-w-30 max-w-30"
                                        alt=""/>
                                    <Typography variant="body2">{t("text_reward")}</Typography>
                                </Link>
                            </Grid>
                            <Grid item xs={3}>
                                <Link
                                    data-auto="nav_gestation"
                                    to="/gestation"
                                    component={RouterLink}
                                    className="d-flex flex-column align-items-center text-center text-dark nav_gestation"
                                >
                                    <img
                                        src={gestationIcon}
                                        className="mb-2 min-w-30 max-w-30"
                                        alt=""
                                    />
                                    <Typography variant="body2">{t("gestation_text")}</Typography>
                                </Link>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Drawer>

            <ActionSheet
                className="dairy-main-navigation-action-sheet"
                visible={isCustomerMenuOpen}
                onDialogCancel={() => setIsCustomerMenuOpen(false)}
                cancelable={true}>
                <div className="action-sheet-title action-sheet-title--material dairy-customer-menu">
                    <div className="action-sheet-title-img">
                        <img src={customerImg} alt="" />
                    </div>
                    {t("text_customers")}
                </div>
                {hasAccess(ABILITY_DAIRY_CUSTOMERS, PERMISSION_VIEW) && <ActionSheetButton
                    data-auto="nav_customer_list"
                    onClick={e => changePage("/customers")}
                >
                    {t("text_customers_list")}
                </ActionSheetButton>}
                {hasAccess(ABILITY_DAIRY_CUSTOMERS, PERMISSION_ADD) && <ActionSheetButton
                    data-auto="nav_add_customer"
                    feature={"customer_limit"}
                    checkLimit
                    onClick={e => changePage("/customers/add")}
                >
                    {t("text_add_customer")}
                </ActionSheetButton>}
                <ActionSheetButton
                    data-auto="nav_cancel"
                    onClick={() => setIsCustomerMenuOpen(false)}
                >
                    <Icon
                        icon="md-close"
                        className="ons-icon action-sheet-icon action-sheet-icon--material zmdi zmdi-close"
                        modifier="material"/>
                    {t("text_cancel")}
                </ActionSheetButton>
            </ActionSheet>

            <ActionSheet
                className="dairy-main-navigation-action-sheet"
                isOpen={isMilkMenuOpen}
                animation='none'
                onCancel={() => setIsMilkMenuOpen(false)}
                isCancelable={true}>
                <div className="action-sheet-title action-sheet-title--material">
                    <div className="action-sheet-title-img">
                        <img src={milkImg} alt="" />
                    </div>
                    {t("text_buy_sell")}
                </div>
                {milkSubMenus.map(milkSubMenu => milkSubMenu.hasAccess && <ActionSheetButton
                        key={milkSubMenu.testid}
                        data-auto={milkSubMenu.testid}
                        onClick={milkSubMenu.onClick}
                        className={milkSubMenu.isMobile ? (!isMobile && "d-none") : ""}
                    >
                        {milkSubMenu.text}
                    </ActionSheetButton>
                )}
                <ActionSheetButton onClick={() => setIsMilkMenuOpen(false)}>
                    <Icon
                        icon="md-close"
                        className="ons-icon action-sheet-icon action-sheet-icon--material zmdi zmdi-close"
                        modifier="material"
                    />
                    {t("text_cancel")}
                </ActionSheetButton>
            </ActionSheet>

            <ActionSheet
                className="dairy-main-navigation-action-sheet"
                isOpen={isProductMenuOpen}
                animation='none'
                onCancel={() => setIsProductMenuOpen(false)}
                isCancelable={true}>
                <div className="action-sheet-title action-sheet-title--material">
                    <div className="action-sheet-title-img">
                        <img src={productImg} alt="" />
                    </div>
                    {t("text_products")}
                </div>
                {hasAccess(ABILITY_PRODUCT_SALE, PERMISSION_SALE_ADD) && <ActionSheetButton
                    className={!isMobile && "d-none"}
                    data-auto="nav_add_sales"
                    onClick={() => 
                        changePage("/dairy/product/addSales", "product_management")
                    }
                >
                    {t("text_add_product_sell")}
                </ActionSheetButton>}
                {hasAccess(ABILITY_PRODUCT_SALE, PERMISSION_SALE_ADD) && <ActionSheetButton
                    data-auto="nav_add_sales"
                    onClick={e => 
                        changePage("/dairy/product/salesSummary", "product_management")
                    }
                >
                    {!isMobile ? `${t("text_add_product_sell")} / ` : null} {t("text_product_summary")}
                </ActionSheetButton>}
                {hasAccess(ABILITY_PRODUCT_MANAGEMENT, PERMISSION_VIEW) && <ActionSheetButton
                    data-auto="nav_manage_products"
                    onClick={() => 
                        changePage("/dairy/product", "product_management")
                    }
                >
                    {t("text_manage_products")}
                </ActionSheetButton>}
                <ActionSheetButton onClick={() => setIsProductMenuOpen(false)}>
                    <Icon
                        icon="md-close"
                        className="ons-icon action-sheet-icon action-sheet-icon--material zmdi zmdi-close"
                        modifier="material"
                    />
                    {t("text_cancel")}
                </ActionSheetButton>
            </ActionSheet>

            <ActionSheet
                className="dairy-main-navigation-action-sheet"
                isOpen={isBillMenuOpen}
                animation='none'
                onCancel={() => setIsBillMenuOpen(false)}
                isCancelable={true}>
                <div className="action-sheet-title action-sheet-title--material">
                    <div className="action-sheet-title-img">
                        <img src={billImg} alt="" />
                    </div>
                    {t("text_bill")}
                </div>
                {hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_DAIRY_BILL) && <ActionSheetButton
                    data-auto="nav_dairy_bill"
                    onClick={() => changePage("/dairy/bill", "dairy_billing")}
                >
                    {t("text_dairy_bill")}
                </ActionSheetButton>}
                {hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_CUSTOMER_BILL) && <ActionSheetButton
                    data-auto="nav_customers_bill"
                    onClick={() =>
                        changePage("/dairy/bill/customer", "customer_billing")
                    }
                >
                    {t("text_customers_bill")}
                </ActionSheetButton>}
                {(
                    hasAccess(ABILITY_ADVANCE_PAYMENT, PERMISSION_VIEW)
                    || hasAccess(ABILITY_ADVANCE_PAYMENT, PERMISSION_ADD)
                ) && <ActionSheetButton
                    data-auto="nav_pay_in_advance"
                    onClick={() => 
                        changePage("/dairy/advancePayment", "advance_payment")
                    }
                >
                    {t("text_pay_in_advance")}
                </ActionSheetButton>}
                {hasAccess(ABILITY_DAIRY_BILL, PERMISSION_VIEW_CUSTOMER_BILL) && <ActionSheetButton
                    data-auto="nav_payment_summary"
                    onClick={e => 
                        changePage("dairy/paymentSummary")
                    }
                >
                    {t("text_payment_summary")}
                </ActionSheetButton>}
                <ActionSheetButton 
                    onClick={() => setIsBillMenuOpen(false)}
                >
                    <Icon
                        icon="md-close"
                        className="ons-icon action-sheet-icon action-sheet-icon--material zmdi zmdi-close"
                        modifier="material"
                    />
                    {t("text_cancel")}
                </ActionSheetButton>
            </ActionSheet>

            <ActionSheet
                className="dairy-main-navigation-action-sheet"
                isOpen={isLoanMenuOpen}
                animation='none'
                onCancel={() => setIsLoanMenuOpen(false)}
                isCancelable={true}>
                <div className="action-sheet-title action-sheet-title--material">
                    <div className="action-sheet-title-img">
                        <img src={loanImg} alt="" />
                    </div>
                    {t("text_loan")}
                </div>
                {hasAccess(ABILITY_LOAN, PERMISSION_ADD) && <ActionSheetButton
                    className={isBrowser && "d-none"}
                    data-auto="nav_sanction_loan"
                    onClick={e => changePage("/dairy/loan/sanction")}
                >
                    {t("text_sanction_loan")}
                </ActionSheetButton>}
                {hasAccess(ABILITY_LOAN, PERMISSION_VIEW) && <ActionSheetButton
                    data-auto="nav_loan_dashboard"
                    onClick={e => changePage("/dairy/loan")}
                >
                    {t("text_loan_dashboard")}
                </ActionSheetButton>}
                {hasAccess(ABILITY_LOAN, PERMISSION_VIEW) && <ActionSheetButton
                    className={isBrowser && "d-none"}
                    data-auto="nav_customers_loan"
                    onClick={e => changePage("/dairy/loan/customer")}
                >
                    {t("text_customers_loan")}
                </ActionSheetButton>}
                <ActionSheetButton onClick={() => setIsLoanMenuOpen(false)}>
                    <Icon
                        icon="md-close"
                        className="ons-icon action-sheet-icon action-sheet-icon--material zmdi zmdi-close"
                        modifier="material"
                    />
                    {t("text_cancel")}
                </ActionSheetButton>
            </ActionSheet>

            <ActionSheet
                className="dairy-main-navigation-action-sheet"
                isOpen={isSettingMenuOpen}
                animation='none'
                onCancel={() => setIsSettingMenuOpen(false)}
                isCancelable={true}>
                <div className="action-sheet-title action-sheet-title--material">
                    <div className="action-sheet-title-img">
                        <img src={settingImg} alt="" />
                    </div>
                    {t("text_settings")}
                </div>
                {hasAccess(ABILITY_DAIRY_RATE_LIST, PERMISSION_VIEW) && <ActionSheetButton
                    data-auto="nav_rate_list"
                    onClick={e => changePage("/dairy/setting/rateList")}
                >
                    {t("text_rate_list")}
                </ActionSheetButton>}
                {hasAccess(ABILITY_DAIRY_SETTING, PERMISSION_VIEW) && <ActionSheetButton
                    data-auto="nav_dairy_setting"
                    onClick={e => changePage("/dairy/setting")}
                >
                    {t("text_dairy_settings")}
                </ActionSheetButton>}
                <ActionSheetButton onClick={() => setIsSettingMenuOpen(false)}>
                    <Icon
                        icon="md-close"
                        className="ons-icon action-sheet-icon action-sheet-icon--material zmdi zmdi-close"
                        modifier="material"/>
                    {t("text_cancel")}
                </ActionSheetButton>
            </ActionSheet>
        </>
    );
};

DairyMainNavigation.propTypes = {
    t: PropTypes.func.isRequired
};

export default withLanguage(DairyMainNavigation);